import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { globalHistory } from '@reach/router';
import Layout from '../components/layout';
import ArticlePreview from '../components/article-preview';
import Navigation from '../components/navigation';
import { css } from '@emotion/core';
import SEO from '../components/seo';
import qs from 'qs';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  // SearchBox,
  connectSearchBox,
  connectHits,
  // Pagination,
  connectPagination,
} from 'react-instantsearch-dom';

const DEBOUNCE_TIME = 400;
const searchClient = algoliasearch(
  'BPTZPE03MP',
  'cc23cbee12e154253dcc3bd045cdf511'
);

const BlogIndex = ({ data, location, history }) => {
  const posts = data.allSanityPost.edges;
  const defaultBlogImage = data.defaultBlogImage;

  const blogWrapper = css`
    max-width: 1000px;
    margin: var(--size-4);

    @media (min-width: 1048px) {
      margin: var(--size-10) auto;
    }
  `;
  const articleList = css`
    margin: var(--size-8) 0 0 0;
    list-style: none;
  `;

  const searchBoxStyles = css`
    form {
      display: flex;
      flex-flow: row nowrap;
      height: var(--size-9);
      border: solid 1px #ccc;
      border-radius: 8px;
      padding-right: var(--size-1);

      input {
        width: 100%;
        border: none;
        border-radius: inherit;
        padding-left: var(--size-1);
      }

      button {
        background: none;
        border: none;

        &.ais-SearchBox-reset {
          cursor: pointer;
        }

        svg {
          &.ais-SearchBox-submitIcon {
            width: var(--size-2);
            height: auto;
            path {
              fill: #999;
            }
          }
          &.ais-SearchBox-resetIcon {
            width: var(--size-1);
            height: auto;
            path {
              fill: #666;
            }
          }
        }
      }
    }
  `;

  const paginationStyles = css`
    margin: 0 auto;
    max-width: 300px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    cursor: pointer;
    ul {
      margin: 0 auto;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      list-style: none;
      padding: 0;
    }

    li {
      margin: 0 var(--size-1);

      span {
        display: none;
      }
    }
  `;

  const CustomHits = connectHits(ArticlePreview);

  let urlQuery = globalHistory.location.search
    ? decodeURIComponent(
        globalHistory.location.search
          .replace('?query=', '')
          .replace('&page=', '')
      )
    : '';

  // let urlPage = window.history.replaceState(null, null, '?query=&page=1');

  let urlPage = globalHistory.location.search
    ? decodeURIComponent(globalHistory.location.search)
    : '&page=1';

  console.log(urlPage);

  // const updateQuery = (query) =>
  //   window.history.replaceState(null, null, `?query=${query}`);

  const updateQuery = (query, page) => {
    window.history.replaceState(
      null,
      null,
      page
        ? `?query=${encodeURIComponent(query)}&page=${encodeURIComponent(
            page
          ).replace(/%20/g, '+')}`
        : query
        ? `?query=${encodeURIComponent(query)}`
        : '?'
    );
  };

  const userSearch = (searchValue, refine) => {
    let currentQuery = getSearch();
    refine(searchValue);
    updateQuery(searchValue, currentQuery.page);
  };

  const userPage = (page, refine) => {
    let currentQuery = getSearch();
    refine(page);
    updateQuery(currentQuery.query === null ? '' : currentQuery.query, page);
  };

  const getSearch = () => {
    let currentSearch = new URLSearchParams(window.location.search);
    let parameters = {
      query: currentSearch.get('query'),
      page: currentSearch.get('page'),
    };
    return parameters;
  };

  const resetSearch = (refine) => {
    refine('');
    updateQuery('', 1);
  };

  const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => {
    return (
      <div css={searchBoxStyles}>
        <form
          noValidate
          role='search'
          onSubmit={(e) => {
            e.preventDefault();
            const searchValue = new FormData(e.target).get('search-input');
            // refine(value);
            userSearch(searchValue, refine);
          }}
        >
          <input name='search-input' type='search' placeholder='Search here' />
          <button onClick={() => resetSearch(refine)} type='button'>
            Reset query
          </button>
          {isSearchStalled ? (
            <p>Sorry, search is stalling, please wait a moment.</p>
          ) : (
            ''
          )}
        </form>
      </div>
    );
  };
  const CustomSearchBox = connectSearchBox(SearchBox);

  const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => (
    <div css={paginationStyles}>
      <ul>
        {new Array(nbPages).fill(null).map((_, index) => {
          const page = index + 1;
          const style = {
            fontWeight: currentRefinement === page ? 'bold' : '',
          };
          return (
            <li key={index}>
              <a
                // href={page}
                style={style}
                onClick={(event) => {
                  userPage(page, refine);
                }}
              >
                {page}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );

  const CustomPagination = connectPagination(Pagination);

  return (
    <Layout location={location}>
      <SEO title='Blog' description='The latest articles from Tri-Rivington' />
      <Navigation solid />
      <div style={{ background: '#fff' }}>
        {/* <div className={styles.hero}>Blog</div> */}

        <div css={blogWrapper}>
          <InstantSearch searchClient={searchClient} indexName='Blog'>
            {/* <SearchBox
              css={searchBoxStyles}
              searchAsYouType={false}
              focusShortcuts={['s']}
            /> */}
            {/* <h2>Recent articles</h2> */}

            <CustomSearchBox defaultRefinement={urlQuery} />

            <ul css={articleList}>
              <CustomHits defaultImage={defaultBlogImage} posts={posts} />
              {/* <Pagination
                showLast={false}
                showFirst={false}
                css={paginationStyles}
                translations={{
                  previous: 'Previous',
                  next: 'Next',
                  first: '«',
                  last: '»',
                  page(currentRefinement) {
                    return currentRefinement;
                  },
                  ariaPrevious: 'Previous page',
                  ariaNext: 'Next page',
                  ariaFirst: 'First page',
                  ariaLast: 'Last page',
                  ariaPage(currentRefinement) {
                    return `Page ${currentRefinement}`;
                  },
                }}
              /> */}

              <CustomPagination
              // defaultRefinement={urlPage === null ? [] : urlPage}
              />
            </ul>
          </InstantSearch>
        </div>
      </div>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query BlogIndexQuery {
    site {
      siteMetadata {
        title
      }
    }

    defaultBlogImage: sanityImageAsset(
      id: { eq: "image-5a5dfd5fa97641ce03ddb7bcd5d5457bc2002048-4896x3264-jpg" }
    ) {
      fluid(maxWidth: 350, maxHeight: 196) {
        ...GatsbySanityImageFluid_noBase64
      }
    }

    # allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
    #   edges {
    #     node {
    #       title
    #       slug
    #       publishDate(formatString: "MMMM Do, YYYY")
    #       tags
    #       heroImage {
    #         fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: FILL) {
    #           ...GatsbyContentfulFluid_withWebp
    #         }
    #       }
    #       description {
    #         childMarkdownRemark {
    #           html
    #         }
    #       }
    #     }
    #   }
    # }

    allSanityPost(sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          title
          postExcerpt
          publishedAt
          prettyDate: publishedAt(formatString: "MMMM Do, YYYY")
          heroImage {
            asset {
              fluid {
                ...GatsbySanityImageFluid_noBase64
                srcSet
              }
            }
          }
        }
      }
    }
  }
`;
