import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { css } from '@emotion/core';
import styles from './article-preview.module.css';
import { titleCase } from 'title-case';

export default ({ hits, posts, defaultImage }) => {
  const articleWrapper = css`
    display: flex;
    flex-flow: row wrap;
  `;

  const articleImage = css`
    width: 100%;
    height: 300px;

    @media (min-width: 768px) {
      width: 50%;
    }
  `;

  const articleText = css`
    color: var(--colour-text);
    width: 100%;
    margin: var(--size-4) 0 0 0;

    @media (min-width: 768px) {
      width: calc(50% - var(--size-4));
      margin: 0 0 0 var(--size-4);
    }

    p {
      font-size: var(--size-1);
    }
  `;

  return (
    <>
      {hits.map((hit) => {
        return (
          hit?.slug?.current && (
            <li
              css={css`
                margin-bottom: var(--size-10);
              `}
              key={hit.objectID}
            >
              <div className={styles.preview}>
                <div css={articleWrapper} to={`/blog/${hit.slug.current}`}>
                  <Link css={articleImage} to={`/blog/${hit.slug.current}`}>
                    {hit.heroImage ? (
                      <img
                        alt=''
                        srcSet={hit.heroImage?.asset.fluid.srcSet}
                        src={hit.heroImage?.asset.fluid.src}
                        sizes='(min-width: 800px) 800px, 100vw'
                        css={css`
                          object-fit: cover;
                          width: 100%;
                          height: 100%;
                        `}
                      />
                    ) : (
                      <Img
                        alt=''
                        fluid={defaultImage.asset.fluid}
                        css={css`
                          object-fit: cover;
                          width: 100%;
                          height: 100%;
                        `}
                      />
                    )}
                  </Link>

                  <div css={articleText}>
                    <Link to={`/blog/${hit.slug.current}`}>
                      <h3>{titleCase(hit.title)}</h3>
                    </Link>
                    <small>{hit.prettyDate}</small>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: hit.postExcerpt,
                      }}
                    />
                  </div>
                </div>
              </div>
            </li>
          )
        );
      })}
    </>
  );
};
